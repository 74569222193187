import React from 'react';
import Helmet from 'react-helmet';
import { Header, Grid, Item, Label, List, Image, Divider } from 'semantic-ui-react';

import '../style/about.css';
import Layout from '../components/Layout';

import Ameet from '../data/ameet.jpeg';
import Brian from '../data/brian.jpeg';
import Nick from '../data/nick.jpeg';


export default ({ data, location }) => {
  return (
    <Layout>
      <Helmet
        title={`Full Scope Web | About`}
        meta={[
          { name: 'description', content: 'Full Scope Web is a Boston-based custom web design and development firm that combines creative design with modern development best practices.' },
        ]}
      />
      <Grid className="about" stackable divided>
        <Grid.Column width={13}>
          <Header>About Full Scope Web</Header>
          <p className="about-word">
            tel·e·scope<br/>
            /ˈteləˌskōp/<br />
            noun<br />
          </p>
          <p className="about-definition">
            an optical instrument designed to make distant objects appear nearer, containing an arrangement of lenses, or of curved mirrors and lenses, by which rays of light are collected and focused and the resulting image magnified.
          </p>
          <p>
            Telescopes use carefully positioned mirrors to make the distant and unknown more tangible. Their precise calculations can mutate a galaxy of mere imagination into a perceptible experience. Full Scope Web similarly seeks to transform abstract concepts into living web presences that the world can interact with.
          </p>
          <p>
            Over the last 3 years, Full Scope Web has guided numerous brands from brainchild to functional product. At the core of our methodology is the application of machine efficiency to human creativity, and our process breaks down into 4 simple steps.
          </p>
          <List ordered>
            <List.Item>
              <span className="about-emphasis">We listen</span> to you talk about your ideas.
            </List.Item>
            <List.Item>
              <span className="about-emphasis">We synthesize</span> your ideas into tangible goals.
            </List.Item>
            <List.Item>
              <span className="about-emphasis">We design</span> these goals into a visual project.
            </List.Item>
            <List.Item>
              <span className="about-emphasis">We craft</span> the project to digital life.
            </List.Item>
          </List>
          <p>
            By repeatedly synthesizing abstract ideas into concrete products, we aim to develop a greater understanding of the mechanics of imagination and push the creative limits of automation. The worlds of human and machine are merging. Full Scope Web is committed to recognizing the beauty of both domains, while establishing the superiority of their union over the sum of their parts.
          </p>
        </Grid.Column>
        <Grid.Column width={3}>
          <Grid columns={1} textAlign="center" verticalAlign="middle">
            <Grid.Column>
              <Image
                className="about-thumbnail"
                circular
                size="tiny"
                src={Ameet}
              />
              <h5 className="about-name">Ameet Kallarackal</h5>
            </Grid.Column>
            <Grid.Column>
              <Image
                className="about-thumbnail"
                circular
                size="tiny"
                src={Nick}
              />
              <h5 className="about-name">Nick Loeper</h5>
            </Grid.Column>
            <Grid.Column>
              <Image
                className="about-thumbnail"
                circular
                size="tiny"
                src={Brian}
              />
              <h5 className="about-name">Brian Kang</h5>
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid>
    </Layout>
  );
};
